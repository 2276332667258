import Vue from 'vue'
import {Tiers} from '@/libs/enums/Tiers'

export default [
  {
    header: 'menu.category.dashboard'
  },
  {
    title: 'menu.subcategory.dashboard',
    route: 'home',
    icon: 'GridIcon'
  },
  {
    header: 'menu.category.material',
    role: [Vue.prototype.$permissions.InventoryManagement],
    tier: [Tiers.Standard, Tiers.Premium]
  },
  {
    title: 'menu.subcategory.delivery_notes',
    route: 'delivery_notes',
    icon: 'TruckIcon',
    additional_active_routes: ['add_delivery_note', 'edit_delivery_note'],
    role: [Vue.prototype.$permissions.InventoryManagement],
    tier: [Tiers.Standard, Tiers.Premium]
  },
  {
    title: 'menu.subcategory.stock_taking',
    route: 'stock_taking',
    icon: 'BookIcon',
    additional_active_routes: ['add_stock_taking'],
    role: [Vue.prototype.$permissions.InventoryManagement],
    tier: [Tiers.Standard, Tiers.Premium]
  },
  {
    title: 'menu.subcategory.write_off',
    route: 'write_off',
    additional_active_routes: ['add_write_off'],
    role: [Vue.prototype.$permissions.InventoryManagement],
    tier: [Tiers.Standard, Tiers.Premium]
  },
  {
    title: 'menu.subcategory.move_supply',
    route: 'move_supplies',
    icon: 'PackageIcon',
    additional_active_routes: ['move_supply'],
    role: [Vue.prototype.$permissions.InventoryManagement],
    tier: [Tiers.Standard, Tiers.Premium]
  },
  {
    title: 'menu.subcategory.suppliers',
    route: 'suppliers',
    icon: 'TruckIcon',
    role: [Vue.prototype.$permissions.InventoryManagement],
    tier: [Tiers.Standard, Tiers.Premium]
  },
  {
    header: 'menu.category.invoices',
    role: [Vue.prototype.$permissions.InvoicesRead]
  },
  {
    title: 'menu.subcategory.invoices',
    route: 'invoices',
    icon: 'FileIcon',
    role: [Vue.prototype.$permissions.InvoicesRead]
  },
  {
    title: 'menu.subcategory.unissued_invoices',
    route: 'unissued_invoices',
    icon: 'FileIcon',
    additional_active_routes: ['add_invoice', 'edit_invoice'],
    role: [Vue.prototype.$permissions.InvoicesRead]
  },
  {
    header: 'menu.category.reporting',
    role: [Vue.prototype.$permissions.ReportingRead, Vue.prototype.$permissions.DeletedRead]
  },
  {
    title: 'menu.subcategory.bills',
    route: 'issued_bills',
    icon: 'FileIcon',
    additional_active_routes: ['preview_bill'],
    role: [Vue.prototype.$permissions.ReportingRead]
  },
  {
    title: 'menu.subcategory.reports',
    route: 'reports',
    icon: 'DivideSquareIcon',
    role: [Vue.prototype.$permissions.ReportingRead]
  },
  {
    title: 'menu.subcategory.removed',
    route: 'deleted',
    icon: 'DeleteIcon',
    children: [
      {
        title: 'menu.subcategory.deleted_products',
        route: 'deleted_products'
      },
      {
        title: 'menu.subcategory.deleted_bills',
        route: 'deleted_open_tabs'
      }
    ],
    role: [Vue.prototype.$permissions.DeletedRead],
    tier: [Tiers.Standard, Tiers.Premium],
    display_variable_from_config: 'setup/deletedConfig'
  },
  {
    header: 'menu.category.keys', //Šifranti
    role:[Vue.prototype.$permissions.ProductsRead, Vue.prototype.$permissions.GeneralRead]
  },
  {
    title: 'menu.subcategory.products',
    route: 'products',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'menu.subcategory.products',
        route: 'products'
      },
      {
        title: 'menu.subcategory.categories',
        route: 'product_categories'
      },
      {
        title: 'menu.subcategory.subcategories',
        route: 'product_subcategories'
      },
      {
        title: 'menu.subcategory.product_order_options',
        route: 'product_order_options'
      },
      {
        title: 'menu.subcategory.products_in_business_units',
        route: 'products_in_business_units'
      }
    ],
    role:[Vue.prototype.$permissions.ProductsRead]
  },
  {
    title: 'menu.subcategory.ingredients',
    route: 'ingredients',
    icon: 'CoffeeIcon',
    children: [
      {
        title: 'menu.subcategory.ingredients',
        route: 'ingredients'
      },
      {
        title: 'menu.subcategory.categories',
        route: 'ingredientsCategory'
      },
      {
        title: 'menu.subcategory.subcategories',
        route: 'ingredient_subcategories'
      }
    ],
    role:[Vue.prototype.$permissions.ProductsRead],
    tier: [Tiers.Standard, Tiers.Premium]
  },
  {
    title: 'menu.subcategory.product_display_groups',
    route: 'product_display_groups',
    role:[Vue.prototype.$permissions.ProductsRead]
  },
  {
    title: 'menu.subcategory.editing_prices',
    route: 'price_list',
    icon: 'DivideSquareIcon',
    children: [
      {
        title: 'menu.subcategory.price_lists',
        route: 'price_list'
      },
      {
        title: 'menu.subcategory.modificators',
        route: 'modificators',
        tier: [Tiers.Premium]
      },
      {
        title: 'menu.subcategory.calendar',
        route: 'calendar',
        icon: 'CalendarIcon',
        tier: [Tiers.Premium]
      }
    ],
    role:[Vue.prototype.$permissions.ProductsRead]
  },
  {
    title: 'menu.subcategory.employees',
    route: 'employees',
    icon: 'UsersIcon',
    children: [
      {
        title: 'menu.subcategory.employees',
        route: 'employees'
      },
      {
        title: 'menu.subcategory.permissions',
        route: 'employee_permission_groups'
      }
    ],
    role:[Vue.prototype.$permissions.GeneralRead]
  },
  {
    title: 'menu.subcategory.buyers',
    route: 'buyers',
    icon: 'UserIcon',
    role:[Vue.prototype.$permissions.GeneralRead]
  },
  {
    title: 'menu.subcategory.payment_types',
    route: 'payment_types',
    icon: 'DollarSignIcon',
    role:[Vue.prototype.$permissions.GeneralRead]
  },
  {
    title: 'menu.subcategory.vat',
    route: 'vat',
    icon: 'PercentIcon',
    role:[Vue.prototype.$permissions.GeneralRead]
  },
  {
    title: 'menu.subcategory.discounts',
    route: 'predefined_discounts',
    icon: 'Edit2Icon',
    role:[Vue.prototype.$permissions.GeneralRead]
  },
  // {
  //   header: 'menu.pos_config',
  //   role:[Vue.prototype.$permissions.PosConfigurationRead]
  // },
  {
    header: 'menu.category.config',
    role:[Vue.prototype.$permissions.ConfigurationRead, Vue.prototype.$permissions.UserConfigurationRead]
  },
  {
    title: 'menu.subcategory.users',
    route: 'users',
    icon: 'UserIcon',
    role:[Vue.prototype.$permissions.UserConfigurationRead]
  },
  {
    title: 'menu.subcategory.tips',
    route: 'tips',
    icon: 'DollarSignIcon',
    role:[Vue.prototype.$permissions.UserConfigurationRead],
    tier: [Tiers.Standard, Tiers.Premium]
  },
  {
    title: 'menu.subcategory.furs_certificates',
    route: 'furs_certificates',
    icon: 'FileIcon',
    role:[Vue.prototype.$permissions.ConfigurationRead]
  },
  {
    title: 'menu.subcategory.cash_registers',
    route: 'cash_register',
    icon: 'HardDriveIcon',
    role:[Vue.prototype.$permissions.ConfigurationRead]
  },
  {
    title: 'menu.subcategory.printer_configs',
    route: 'printer_configs',
    icon: 'SettingsIcon',
    role:[Vue.prototype.$permissions.ConfigurationRead]
  },
  {
    title: 'menu.subcategory.logos',
    route: 'logo',
    icon: 'ImageIcon',
    role:[Vue.prototype.$permissions.ConfigurationRead]
  },
  {
    title: 'menu.subcategory.premises',
    icon: 'HomeIcon',
    children: [
      {
        title: 'menu.subcategory.warehouses',
        route: 'warehouses',
        icon: 'BriefcaseIcon',
        additional_active_routes: ['warehouse_stock'],
        tier: [Tiers.Standard, Tiers.Premium]
      },
      {
        title: 'menu.subcategory.business_units',
        route: 'business_units'
      },
      {
        title: 'menu.subcategory.work_premises',
        route: 'work_premises',
        additional_active_routes: ['view_room'],
        tier: [Tiers.Standard, Tiers.Premium]
      }
    ],
    role:[Vue.prototype.$permissions.ConfigurationRead]
  },
  {
    title: 'menu.subcategory.configs',
    route: 'configuration',
    icon: 'SettingsIcon',
    role:[Vue.prototype.$permissions.ConfigurationRead, Vue.prototype.$permissions.ConfigurationWrite],
    display_variable_from_config: 'setup/woltIntegration'
  },
  {
    header: 'menu.category.wolt',
    role:[Vue.prototype.$permissions.WoltIntegrationRead],
    display_variable_from_config: 'setup/woltIntegration'
  },
  {
    title: 'menu.subcategory.wolt_menu',
    route: 'wolt_menu',
    icon: 'GridIcon',
    role:[Vue.prototype.$permissions.WoltIntegrationRead],
    display_variable_from_config: 'setup/woltIntegration'
  },
  {
    title: 'menu.subcategory.wolt_configs',
    route: 'wolt_configs',
    icon: 'SettingsIcon',
    role:[Vue.prototype.$permissions.WoltIntegrationWrite],
    display_variable_from_config: 'setup/woltIntegration'
  }
]